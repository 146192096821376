import { graphql } from "gatsby";
import Link from "gatsby-link";
import { get, kebabCase } from "lodash";
import React, { Fragment, useEffect } from "react";
import { EVENTS } from "@upsolve/shared";
import { H5, HR, P, Small, theme } from "@upsolve/ui";
import styled from "styled-components";
import * as CONTENT_CREATION_METHODS from "../components/analytics/contentCreationMethods";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import Article from "../components/Layout/Article.div";
import AuthorBio from "../components/Layout/AuthorBio";
import AuthorsBlock from "../components/Layout/AuthorsBlock";
import CTASideBySideOfferings from "../components/CTA/CTASideBySideOfferings";
import CitationsList from "../components/Layout/CitationsList";
import ContentSectionFooter from "../components/Layout/ContentSectionFooter";
import HeroFilerCta from "../components/CTA/HeroFilerCta";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import RelatedArticles from "../components/Programmatic/RelatedArticles";
import SEO from "../components/SEO/SEO";
import TableOfContents from "../components/Layout/TableOfContents";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import UpsolveTextBio from "../components/ProofBuilders/UpsolveTextBio";
import VideoInContext from "../components/Media/VideoInContext";
import checkForInlineCta from "../components/Contentful/checkForInlineCta";
import getCitationsFromRichText from "../components/Contentful/getCitationsFromRichText";
import getHeadersFromRichText from "../components/Contentful/getHeadersFromRichText";
import getWordCountFromRichText from "../components/Contentful/getWordCountFromRichText";
import richTextNodeToComponent from "../components/Contentful/richTextNodeToComponent";
import richTextNodesInSections from "../components/Contentful/richTextNodesInSections";
import { track } from "../components/analytics/track";
import withGeoLocation from "../components/maps/withGeoLocation";
import withScrollWatcher from "../components/analytics/withScrollWatcher";
import { Ampify } from "../components/AMP/AmpContext";
import { AJMArticleSlugs } from "../components/CTA/TempAJMArticles";
import { CTATarget } from "../components/CTA/getCTAData";
import { learnTagsSortedByPriority } from "../components/Learn/learnTagPriority";
import { $TSFixMe } from "@upsolve/shared/dist/types";
import { fixHeaderScrollOffset } from "../components/Layout/fixHeaderScrollOffset";

const contentGroup = CONTENT_GROUPS.LEARN_ARTICLE;
const templateProps = {
  pageComponentName: "LearnArticle",
  pageComponentVersion: "4",
};

const getArticleTarget = (slug: string, tags: { value: string }[]) => {
  //future: use the graphQL query instead of this static list
  if (AJMArticleSlugs.find((ajmSlug) => slug.includes(ajmSlug))) {
    return CTATarget.AJM;
  } else if (tags.find((tag) => tag.value === learnTagsSortedByPriority.STUDENT_LOANS)) {
    return CTATarget.STUDENT_LOANS;
  } else if (tags.find((tag) => tag.value === learnTagsSortedByPriority.CONSUMER_RIGHTS)) {
    return CTATarget.DEBT_TRIAGE;
  }
  return CTATarget.SCREENER;
};

/**
 * LearnArticle
 * - v0: Where we began.
 * - v1: Added geolocation region to persistent cta text
 * - v2: Layout adjustments - condensed top, collapsed TOC, hide CTAs based on screen width
 * - v3: ContentSectionFooter removed, share button at top added, more intuitive Table of Contents
 * - v4: Added mobile CTA, moved authors block to after sidebar
 *
 * @component
 * @version 4
 */
type LearnArticleProps = {
  data: { article: $TSFixMe; relatedArticles: { nodes: $TSFixMe } };
  geoLocation: { regionName: string };
  pageContext: { amp: boolean; slug: string };
  scrollWatcher: $TSFixMe;
};

const LearnArticle = (props: LearnArticleProps) => {
  const { geoLocation, pageContext, scrollWatcher } = props;
  const { amp, slug } = pageContext;
  const contentNode = props.data.article;
  const seoNode = contentNode.seo;

  const content = get(contentNode, "content.json");
  const sectionHeaders = getHeadersFromRichText(content);
  const citations = getCitationsFromRichText(content);
  const { video } = contentNode;
  const articleTags = contentNode.tags?.filter((tag: { hasDirectoryPage: boolean }) => tag.hasDirectoryPage) || [];
  const hasInlineCta = checkForInlineCta(content.json);
  const cta = `File Bankruptcy${geoLocation && geoLocation.regionName ? ` in ${geoLocation.regionName}` : " for Free"}`;
  const authors = (contentNode.authors || [contentNode.author]).filter(
    (author: { name: string | null }) => author && author.name != null
  );
  const wordCount = getWordCountFromRichText(get(props, "data.article.content.json", []));
  const relatedArticles = props.data.relatedArticles.nodes;
  const trackingPageContext = {
    contentCreationMethod: CONTENT_CREATION_METHODS.WRITTEN,
    contentGroup,
    contentLocalized: get(props, "data.article.locality") != null,
    publishedAt: get(props, "data.article.publishedAt"),
    slug,
    tags: articleTags.map((tag: { value: string }) => ({ tag: tag.value })),
    updatedAt: get(props, "data.article.updatedAt"),
    wordCount,
    ...templateProps,
  };

  const ctaTarget = getArticleTarget(slug, articleTags);

  // On Mount
  useEffect(() => {
    track(EVENTS.PAGE_VIEW, {
      pageContext: {
        ...trackingPageContext,
        wordCount: wordCount || undefined,
      },
    });
  }, []);

  useEffect(() => {
    fixHeaderScrollOffset();
  }, []);

  // Helper functions that could be extracted
  const renderContentSection = (
    section: any,
    sectionIndex: number,
    { hasInlineCta, content, citations, contentGroup, trackingPageContext, ctaTarget }: any
  ) => (
    <Fragment key={sectionIndex}>
      <section>{renderRichTextNodes(section, { contentGroup, citations, trackingPageContext })}</section>
      {shouldShowMobileCta(hasInlineCta, content, sectionIndex) && (
        <div className="mobile-cta-wrapper">
          <aside className="article__sidebar mobile-only">
            <div className="sidebar-hero__cta">
              <HeroFilerCta
                trackingPageContext={trackingPageContext}
                target={ctaTarget}
                dataTestId="inline-content-cta-mobile"
              />
            </div>
          </aside>
        </div>
      )}
    </Fragment>
  );

  const renderRichTextNodes = (section: any, context: any) =>
    Array.prototype.map.call(section, (c, i) => richTextNodeToComponent(c, i, null, context));

  const shouldShowMobileCta = (hasInlineCta: boolean, content: any, sectionIndex: number) =>
    !hasInlineCta && Math.floor(richTextNodesInSections(content).length * 0.35) === sectionIndex;

  // Comp
  const children = (
    <TopLevelLayout showPersistentCta={false} persistentCtaText={cta} trackingPageContext={trackingPageContext}>
      <SEO
        seoNode={seoNode}
        contentNode={{
          ...contentNode,
          citations,
          seoImage: contentNode.seoImage || contentNode.coverImage,
          video,
        }}
      />
      <Header trackingPageContext={trackingPageContext} ctaTarget={ctaTarget} />
      <Article>
        <ArticleNav>
          <div>
            <a aria-busy="false" href="/learn/">
              ← Learning Center
            </a>
          </div>
          {articleTags.length > 0 && (
            <div style={{ display: "flex", gap: "8px" }}>
              <div>Tags: </div>
              {articleTags.map((tag: { value: string; label: string }, index: number) => (
                <div key={tag.value} style={{ display: "flex", gap: "8px" }}>
                  {index > 0 ? <div>|</div> : <></>}
                  <a aria-busy="false" href={`/learn/category/${kebabCase(tag.value)}/`}>
                    {tag.label}
                  </a>
                </div>
              ))}
            </div>
          )}
        </ArticleNav>
        <div className="article__body">
          <div className="article__content">
            <div className="content-hero">
              <header className="content-hero__header">
                <ArticleTitle>{contentNode.title}</ArticleTitle>
                <UpsolveTextBio
                  wordCount={wordCount || undefined}
                  ctaTarget={ctaTarget}
                  trackingPageContext={trackingPageContext}
                />
                <HR />
              </header>
            </div>
            <div className="content-body">
              <article className="content-body__container">
                {get(contentNode, "articleExcerpt.articleExcerpt") != null && (
                  <div className="content-body__lead">
                    <div className="summary">
                      <b>In a Nutshell</b>
                      <P>{get(contentNode, "articleExcerpt.articleExcerpt")}</P>
                    </div>
                  </div>
                )}
                <AuthorsBlock
                  authors={authors}
                  createdAt={contentNode.createdAt}
                  updatedAt={contentNode.updatedAt}
                  reviewedAt={undefined}
                />
                <HR />
                {sectionHeaders.length > 1 && <TableOfContents sectionHeaders={sectionHeaders} />}
                {richTextNodesInSections(content).length === 1
                  ? renderRichTextNodes(richTextNodesInSections(content)[0], {
                      contentGroup,
                      citations,
                      trackingPageContext,
                    })
                  : richTextNodesInSections(content).map((section, sectionIndex) =>
                      renderContentSection(section, sectionIndex, {
                        hasInlineCta,
                        content,
                        citations,
                        contentGroup,
                        trackingPageContext,
                        ctaTarget,
                      })
                    )}
                {video != null && (
                  <section className="content-video">
                    <VideoInContext video={video} />
                  </section>
                )}
                <HR />
                {citations.length > 0 && (
                  <Fragment>
                    <H5 as="h2">Sources:</H5>
                    <CitationsList citations={citations} />
                  </Fragment>
                )}
                <ContentSectionFooter />
                <HR />
                {authors.length > 0 && (
                  <div className="author-bios">
                    <div className="written-by">
                      <Small>Written By:</Small>
                    </div>
                    {Array.prototype.map.call(authors, (author) => (
                      <AuthorBio key={author.name} author={author} />
                    ))}
                    <div className="team">
                      <Link to="/team">Read About the Upsolve Team</Link>
                    </div>
                  </div>
                )}
              </article>
              {/* Desktop CTA - shows only on desktop */}
              <aside className="article__sidebar desktop-only">
                <div className="sidebar-hero__cta">
                  <HeroFilerCta
                    trackingPageContext={trackingPageContext}
                    target={ctaTarget}
                    dataTestId="inline-content-cta-desktop"
                  />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </Article>
      <RelatedArticles articles={relatedArticles} />
      <CTASideBySideOfferings trackingPageContext={trackingPageContext} ctaTarget={ctaTarget} />
      <Footer showArticles />
    </TopLevelLayout>
  );

  return amp === true ? <Ampify>{children}</Ampify> : children;
};

const ArticleTitle = styled.h1`
  color: ${theme.colors.black[300]};
  font-size: 52px;
  font-weight: 500;
  line-height: 58px;
  letter-spacing: -0.5px;
  @media screen and (max-width: 45em) {
    font-size: 36px;
    line-height: 120%;
  }
`;

const ArticleNav = styled.nav`
  @media (max-width: 720px) {
    padding-left: 20px;
  }
`;

//TODO: @Jeff, could not figure out why callToActionTag is returning null, and cannot figure out how to test this query on graphiQL. using a constant list for AJM articles for now.
export const pageQuery = graphql`
  query LearnArticleBySlug($slug: String!, $relatedArticleIds: [String]) {
    article: contentfulLearnArticle(slug: { eq: $slug }) {
      slug
      title
      publishedAt
      tags {
        value
        label
        hasDirectoryPage
      }
      coverImage {
        image {
          file {
            url
          }
          fluid(maxWidth: 960) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      articleExcerpt {
        articleExcerpt
      }
      video {
        ...VideoNode
      }
      author {
        ...AuthorNode
      }
      authors {
        ...AuthorNode
      }
      content {
        json
      }
      seo {
        ...SeoNode
      }
      createdAt
      updatedAt
    }
    relatedArticles: allContentfulLearnArticle(filter: { id: { in: $relatedArticleIds }, slug: { ne: $slug } }) {
      nodes {
        createdAt
        slug
        title
        authors {
          name
        }
      }
    }
  }
`;

export default withScrollWatcher(withGeoLocation(LearnArticle));
